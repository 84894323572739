<template>
  <v-scroll-x-reverse-transition>
    <v-card
      v-model="showModal"
      class="card-container"
      elevation="0"
      width="400"
    >
      <div class="container">
        <div class="close-button">
          <v-btn
            fab
            x-small
            outlined
            @click="showModal = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-skeleton-loader
          v-if="modalLoading == true"
          :loading="modalLoading"
          type="avatar, heading, sentences"
          color="#eceff1"
        />
        <div v-else>
          <div
            v-if="isAppUserDataAvailable"
            class="card-wrapper"
          >
            <div class="user-details">
              <v-icon
                size="100"
                color="rgb(55, 71, 79)"
              >
                mdi-account-circle
              </v-icon>
              <p class="text-h3 font-weight-bold mb-1">
                {{ appUserDetails.first_name }} {{ appUserDetails.last_name }}
              </p>
              <p class="contacts-container">
                <font>
                  {{ appUserDetails.phone_number }}
                </font>
              </p>
            </div>
            <v-row class="justify-center pa-5">
              <v-expansion-panels accordion>
                <v-expansion-panel class="my-panel">
                  <v-expansion-panel-header>
                    Emergency Contacts
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <div v-if="!isAppUserDetailsAvailable">
                      <font class="no-contacts">
                        No Contacts Added
                      </font>
                    </div>

                    <div
                      v-for="(item, i) in appUserDetails.emergency_contact"
                      v-else
                      :key="i"
                    >
                      <p>
                        <font class="status-font-style">
                          Name:
                        </font>
                        <font class="capitalize font-style-text">
                          {{ item.name }}
                        </font>
                      </p>
                      <p>
                        <font class="status-font-style">
                          Number:
                        </font>
                        <font class="font-style-text">
                          {{ item.phone_number }}
                        </font>
                      </p>
                      <p>
                        <font class="status-font-style">
                          Relation:
                        </font>
                        <font class="capitalize font-style-text">
                          {{ item.relation }}
                        </font>
                      </p>
                      <hr>
                      <br>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="my-panel">
                  <v-expansion-panel-header>
                    CheckIn Types
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <div>
                      <p>
                        <font class="font-style-text">
                          {{ appUserDetails.types | arrangePretty }}
                        </font>
                      </p>
                      <hr>
                      <br>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </div>
        </div>
      </div>
      <centre-spinner :loading="loading" />
    </v-card>
  </v-scroll-x-reverse-transition>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
  name: 'EmergencyContact',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    arrangePretty (val) {
      return val.join(', ');
    },
  },
  props: {
    appUserId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showModal: true,
    loading: false,
    modalLoading: false,
  }),
  computed: {
    appUserDetails () {
      return this.$store.getters['appusers/getAppUserDetails'];
    },
    isAppUserDetailsAvailable () {
      if (Object.keys(this.appUserDetails).length > 0 && this.appUserDetails.emergency_contact !== undefined && this.appUserDetails.emergency_contact.length > 0) {
        return true;
      }
      return false;
    },
    isAppUserDataAvailable () {
      if (Object.keys(this.appUserDetails).length > 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  mounted () {
    this.$store.dispatch('appusers/clearEmergencyContactData');
    this.getAppUserDetails();
  },
  methods: {
    async getAppUserDetails () {
      this.modalLoading = true;
      setTimeout(async () => {
        await this.$store.dispatch('appusers/fetchAppUserDetails', {
        appUserId: this.appUserId,
      });
        this.modalLoading = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.card-container {
  position: absolute;
  right: 0;
  height: auto;
  bottom: 0;
  top: 0;
  background: #ffffff;
  border: 1px solid #CCC;
}

.container {
  padding: 20px;
  width: 100%;
}

.v-skeleton-loader--is-loading {
  margin-top: 20px;
}

.v-skeleton-loader>>>.v-skeleton-loader__table-heading {
  background: transparent !important;
}

.v-skeleton-loader>>>.v-skeleton-loader__avatar {
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
}

.v-skeleton-loader>>>.v-skeleton-loader__heading,
.v-skeleton-loader>>>.v-skeleton-loader__text {
  left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
}

.close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.capitalize {
  text-transform: capitalize;
}

.subheader-style p {
  margin-bottom: 0px;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.details-container {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  margin: 4px;
}

.title-text-style {
  font-weight: 500;
  color: rgb(55, 71, 79);
}

.card-wrapper {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contacts-container {
  display: flex;
  align-items: center;
}

.v-expansion-panel-header>*:not(.v-expansion-panel-header__icon) {
  -webkit-box-flex: 1;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.v-expansion-panel-header {
  background-color: rgb(255, 255, 255);
  border: 1px solid #CCC;
  margin-top: 10px;
}

.v-expansion-panel-content {
  background-color: #F8F8F8;
}

.status-font-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  text-transform: capitalize;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.font-style-text {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panels {
  margin-top: 10px !important;
}

.no-contacts {
  font-size: 16px;
  margin-top: 20px;
  color: #C62828;
  text-align: center;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.my-panel {
  margin-bottom: 10px;
}
</style>
