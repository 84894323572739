<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      App Users📱
    </h2>
    <p class="sub-title-style mt-0 mb-0">
      Explore app users list and details here.
    </p>
    <br>
    <v-col
      class="text-right"
    >
      <v-tooltip
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="wrk-hr-btn"
            v-bind="attrs"
            fab
            dark
            outlined
            color="#37474F"
            small
            v-on="on"
            @click="showSetWrkHrsForm = true"
          >
            <v-icon
              small
              dark
            >
              mdi-account-clock-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Set Work Hours</span>
      </v-tooltip>
      <v-btn
        color="#37474F"
        @click="groupAppUsers()"
      >
        Group Users
      </v-btn>
      <v-btn
        color="#000"
        @click="showForm = true"
      >
        <v-icon class="mr-2">
          mdi-plus-circle-outline
        </v-icon>
        Create New
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      class="mt-5"
    >
      <v-tab>
        Active
      </v-tab>
      <v-tab>
        Pending
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <app-users-active @showEmergencyContact="showEmgContactDetails" />
      </v-tab-item>
      <v-tab-item>
        <app-users-pending />
      </v-tab-item>
    </v-tabs-items>
    <centre-spinner
      :loading="loading"
    />
    <create-app-user
      v-if="showForm"
      @closed="showForm = false"
      @success="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
    />
    <set-app-users-work-hours
      v-if="showSetWrkHrsForm"
      @closed="showSetWrkHrsForm = false"
      @success="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
    />
    <v-scroll-x-reverse-transition>
      <emergency-contact
        v-if="showEmgContact"
        transition="slide-x-reverse-transition"
        :app-user-id="appUserId"
        @closed="showEmgContact = false"
      />
    </v-scroll-x-reverse-transition>
  </v-container>
</template>
  <script>
    import AppUsersActive from './AppUsersActive.vue';
    import AppUsersPending from './AppUsersPending.vue';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import CreateAppUser from 'src/views/dashboard/appusers/CreateAppUser';
    import SetAppUsersWorkHours from 'src/views/dashboard/appusers/SetAppUsersWorkHours';
    import EmergencyContact from './EmergencyContact.vue';
    export default {
      name: 'AppUsersList',
      components: {
        'app-users-active': AppUsersActive,
        'app-users-pending': AppUsersPending,
        'centre-spinner': spinner,
        'create-app-user': CreateAppUser,
        'set-app-users-work-hours': SetAppUsersWorkHours,
        'emergency-contact': EmergencyContact,
      },
      data: () => ({
        tab: null,
        showForm: false,
          loading: false,
          showSetWrkHrsForm: false,
          appUserId: '',
          showEmgContact: false,
      }),
      computed: {
        companiesList () {
          return this.$store.getters['companies/getCompaniesList'];
        },
      },
      watch: {
        isSearchAdded (val) {
          if (val === false) {
            this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
          }
        },
      },
      async mounted () {
        await this.getCompaniesList();
      },
      methods: {
        async getCompaniesList () {
          this.loading = true;
          await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        groupAppUsers () {
          this.$router.push({ name: 'App Users Structure' });
        },
        async getRecordsFromServer ({ itemsPerPage, pageStart }) {
          this.loading = true;
          await this.$store.dispatch('appusers/fetchAppUsers', {
            params: {
              params: {
                limit: itemsPerPage,
                startPage: pageStart,
              },
            },
          }).then(response => {
            this.getPendingRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
          }).catch(() => {
            this.loading = false;
          });
        },
        async getPendingRecordsFromServer ({ itemsPerPage, pageStart }) {
          await this.$store.dispatch('appusers/fetchPendingAppUsers', {
            params: {
              params: {
                limit: itemsPerPage,
                startPage: pageStart,
              },
            },
          }).catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        showEmgContactDetails (id) {
          this.showEmgContact = false;
          this.appUserId = id;
          this.$store.dispatch('appusers/clearEmergencyContactData').then(() => {
            this.showEmgContact = true;
          });
        },
      },
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
    font-size: 36px;
    font-weight: 600;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: #CFD8DC
  }
  .v-data-table { background-color: #ECEFF1; }
  .title-style {
    color: #37474F;
  }
  .type-style {
    text-transform: capitalize;
  }
  .all-users-btn {
    margin-bottom: 25px;
  }
  .wrk-hr-btn {
    margin-right: 15px;
  }
  .sub-title-style{
    color: #aaa !important;
  }
  </style>
