<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil
        </v-icon>Add Company
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-combobox
                  :search-input.sync="name"
                  :items="companiesFromOrgSearch"
                  single-line
                  append-icon="mdi-chevron-down"
                  label="Company Name*"
                  item-text="name"
                  item-value="name"
                  dense
                  :error-messages="showErrorMsg"
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="For your reference - 'DBSystems'"
                  persistent-hint
                  @keyup="searchForCompany()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="companyTrade"
                  :items="trades"
                  label="Trade*"
                  dense
                  outlined
                  single-line
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  filled
                  auto-grow
                  rows="2"
                  label="Descripion"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <address-form
                  ref="addressForm"
                  class="address-form"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="phoneNumber"
                  label="Phone Number*"
                  dense
                  type="number"
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="websiteUrl"
                  label="Website URL"
                  dense
                  outlined
                />
              </v-col>
              <br>
              <p class="text-sm-left">
                *Indicates required field
              </p>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              :disabled="disableButton"
              color="#000"
              @click="createCompany()"
            >
              {{ buttonName }}
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddressForm from 'src/views/dashboard/component/AddressForm';

  export default {
    name: 'CreateCompany',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    data () {
      return {
        showModal: true,
        loading: false,
        valid: false,
        name: null,
        abnNumber: null,
        companyTrade: '',
        address: {},
        phoneNumber: null,
        websiteUrl: null,
        description: null,
        buttonName: 'Create',
        showErrorMsg: null,
        disableButton: false,
      };
    },
    computed: {
      trades () {
        return this.$store.getters['companies/getCompanyTradesList'];
      },
      companiesFromOrgSearch () {
        return this.$store.getters['companies/getCompaniesFromOrgSearch'];
      },
      isAnyCompanyExistInOrgSearch () {
        if (this.companiesFromOrgSearch.length > 0) {
          return true;
        }
        return false;
      },
      companiesList () {
        return this.$store.getters['companies/getCompaniesList'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
      name (val) {
        if (this.isAnyCompanyExistInOrgSearch === true && val !== null && this.companiesFromOrgSearch.some(item => item.name.toLowerCase() === val.toLowerCase())) {
          this.buttonName = 'Import';
        } else {
          this.buttonName = 'Create';
        }
        if (this.companiesList.length > 0 && val !== null && this.companiesList.some(item => item.name.toLowerCase() === val.toLowerCase())) {
          this.showErrorMsg = 'Company already exist in account';
          this.disableButton = true;
        } else {
          this.showErrorMsg = null;
          this.disableButton = false;
        }
      },
    },
    async mounted () {
      await this.getCompanyTradesList();
      this.clearCompaniesFromOrgSearch();
    },
    methods: {
      async getCompanyTradesList () {
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompanyTradesList');
        this.loading = false;
      },
      assiginAddress (address) {
        this.address = address;
      },
      async createCompany () {
        if (this.buttonName === 'Create') {
          this.$refs.addressForm.getEnteredAdress();
          if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0) {
            this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.loading = true;
          await this.$store.dispatch('companies/createCompany', {
            name: this.name,
            abn_number: this.abnNumber,
            trade: this.companyTrade,
            address: this.address,
            description: this.description,
            website: this.websiteUrl,
            phone_number: this.phoneNumber,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Comapny created successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.$emit('company-created');
            this.loading = false;
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
          });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('companies/importOrgCompanyToAccount', {
          companyName: {
            name: this.name,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Comapny imported successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('company-created');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      async searchForCompany () {
        await this.$store.dispatch('companies/fetchCompaniesFromOrgSearch', {
          params: {
            params: {
              name: this.name === '' ? null : this.name,
            },
          },
        });
      },
      clearCompaniesFromOrgSearch () {
        this.$store.dispatch('companies/clearCompaniesFromOrgSearch');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.doc-btn-style {
    background-color: #455a64 !important;
}
.subheader-style {
  color: #78909C;
  font-size: 18px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 0px;
}
.my-note-style {
  font-size: 14px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #FF3700;
  margin-bottom: 20px;
}
.address-form{
  border-left-color: #000 !important;
}
</style>
