<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account
        </v-icon> Confirm Details
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="First Name*"
                  :disabled="disableFields"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Last Name*"
                  :disabled="disableFields"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mobileNumber"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Mobile Number*"
                  :disabled="disableFields"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  class="field-style"
                  label="E-mail Address"
                  :rules="emailRules"
                  :disabled="disableFields"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="role"
                  class="field-style"
                  :items="rolesList"
                  label="Role*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  disabled
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="company"
                  class="field-style"
                  :items="companyList"
                  item-text="name"
                  item-value="id"
                  label="Company*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  append-icon="mdi-office-building-plus-outline"
                  outlined
                  disabled
                  dense
                  @click:append="handleIconClick"
                >
                  <template #append>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="handleIconClick"
                        >
                          mdi-office-building-plus-outline
                        </v-icon>
                      </template>
                      <span>Add Company</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="types"
                  class="field-style"
                  :items="typesList"
                  label="Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  multiple
                  hint="Select all possible ways in which this user can Check-In to your site."
                  persistent-hint
                  outlined
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="workerCategory"
                  class="field-style"
                  :items="workerCategories"
                  item-text="name"
                  item-value="id"
                  label="Worker Category"
                  single-line
                  hint="Required only for type 'Worker'"
                  persistent-hint
                  outlined
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="workHours"
                  class="field-style"
                  label="Set Work Hours*"
                  hint="Daily work hours"
                  persistent-hint
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  :disabled="disableFields"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="country"
                  class="field-style"
                  :items="countryList"
                  label="Country*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-switch
                  v-model="hrWorker"
                  label="High Risk Worker"
                  hint="user works under high risk category"
                  persistent-hint
                  color="#FF3700"
                  :disabled="isWorkerSelected"
                  class="hrw-switch"
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="updateAppUser()"
            >
              {{ requestStatus | setStatus }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-company
      v-if="showForm"
      @closed="showForm = false"
      @company-created="fetchCompaniesList"
    />
  </v-dialog>
</template>
  <script>
    import Constants from 'src/constants';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import CreateCompany from 'src/views/dashboard/companies/CreateCompany';

    export default {
      name: 'EditAppUser',
      components: {
        'centre-spinner': spinner,
        'create-company': CreateCompany,
      },
      filters: {
        setStatus (val) {
          if (val === 'pending') {
            return 'Approve';
          }
          return 'Decline';
        },
      },
      props: {
          appUserId: {
            type: String,
            required: true,
          },
          requestStatus: {
            type: String,
            required: true,
          },
      },
      data () {
        return {
          showModal: true,
          firstName: '',
          lastName: '',
          mobileNumber: '',
          loading: false,
          isFormValid: false,
          email: null,
          workerCategory: '',
          typesList: [
            {
              name: 'Worker',
              id: 'worker',
            },
            {
              name: 'Contractor',
              id: 'contractor',
            },
            {
              name: 'Visitor',
              id: 'visitor',
            },
            {
              name: 'Site manager',
              id: 'staff',
            },
          ],
          types: [],
          company: '',
          country: '',
          showForm: false,
          hrWorker: false,
          workHours: null,
          rolesList: [
            {
              name: 'Site Manager',
              id: 'site-manager',
            },
            {
              name: 'Contractor',
              id: 'contractor',
            },
            {
              name: 'Worker',
              id: 'worker',
            },
          ],
          role: null,
        };
      },
      computed: {
        emailRules () {
          if (this.email !== null) {
            return [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'];
          } else {
            return [];
          }
        },
        workerCategories () {
          return this.$store.getters['featuresettings/getWorkerCategoryList'];
        },
        companyList () {
          return this.$store.getters['companies/getCompaniesList'];
        },
        appUserDetails () {
          return this.$store.getters['appusers/getAppUserDetails'];
        },
        CountryZipCodeList () {
          return this.$store.getters['userprofile/getCountryCodes'];
        },
        countryList () {
         return Object.keys(this.CountryZipCodeList).map((key) => ({ text: this.CountryZipCodeList[key], value: this.CountryZipCodeList[key] }));
        },
        isWorkerSelected () {
          if (this.types.includes('worker') || this.requestStatus === 'pending') {
            return false;
          }
          return true;
        },
        disableFields () {
          if (this.requestStatus === 'pending') {
            return false;
          }
          return true;
        },
      },
      watch: {
        showModal: function (newval, oldval) {
          if (newval === false) {
            this.$emit('closed');
          }
        },
      },
      async mounted () {
       await this.loadOnMount();
      },
      methods: {
        async loadOnMount () {
          this.loading = true;
          await this.fetchAppUserDetails();
          await this.$store.dispatch('featuresettings/fetchWorkerCategoryList');
          await this.$store.dispatch('companies/fetchCompaniesList');
          await this.$store.dispatch('userprofile/fetchCountryCodes');
          this.loading = false;
        },
        async updateAppUser () {
          if (this.$refs.form.validate() === false) {
            this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.loading = true;
          await this.$store.dispatch('appusers/updateAppUserDetails', {
            updateDetails: {
              first_name: this.firstName,
              last_name: this.lastName,
              phone_number: this.mobileNumber,
              worker_category_id: this.workerCategory,
              email: this.email,
              types: this.types,
              company_id: this.company,
              high_risk_worker: this.hrWorker,
              work_hours: parseInt(this.workHours),
              user_role: this.role,
            },
            appUserId: this.appUserId,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'App user Status updated successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.$emit('success');
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
          });
        },
        handleIconClick () {
         this.showForm = true;
        },
        async fetchCompaniesList () {
          this.showForm = false;
          this.loading = true;
          await this.$store.dispatch('companies/fetchCompaniesList');
          this.loading = false;
        },
        async fetchAppUserDetails () {
          this.loading = true;
          this.$store.dispatch('appusers/fetchAppUserDetails', {
            appUserId: this.appUserId,
          }).then(response => {
            this.firstName = this.appUserDetails.first_name;
            this.lastName = this.appUserDetails.last_name;
            this.types = this.appUserDetails.types;
            this.mobileNumber = this.appUserDetails.phone_number;
            this.email = this.appUserDetails.email;
            this.workerCategory = this.appUserDetails.worker_category !== null ? this.appUserDetails.worker_category.id : null;
            this.company = this.appUserDetails.company.id;
            this.country = this.appUserDetails.country_code;
            this.hrWorker = this.appUserDetails.high_risk_worker;
            this.workHours = this.appUserDetails.work_hours;
            this.role = this.appUserDetails.app_user_role;
            this.loading = false;
            }).catch(() => {
            this.loading = false;
          });
        },
      },
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
    margin-left: 21px;
  }
  .icon-style {
    color: #37474F;
    font-size: 55px;
    margin-right: 5px;
  }
  .my-container-style {
    padding-top: 24px;
  }
  .hrw-switch {
    margin-top: -20px;
  }
  .field-style {
    margin-top: -10px;
  }
  </style>
