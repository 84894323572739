<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card "
    >
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              persistent-hint
              dense
              @keyup.enter="handleSearch"
              @keydown.esc="clearHandleSearch"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="4"
            md="2"
            sm="1"
            class="text-right"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getAllRecordsFromServer()"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-filter-off
                  </v-icon>
                </v-btn>
              </template>
              <span>All App Users</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="11"
          >
            <v-autocomplete
              v-model="selectedCompany"
              :items="companiesList"
              item-text="name"
              item-value="id"
              label="Company*"
              single-line
              append-icon="mdi-office-building-plus-outline"
              outlined
              dense
              @input="handleCompanyFilter()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="appUsers"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
        light
        :options.sync="options"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td @click="showEmgContactDetails(item.external_id)">
              <span class="info-container">
                {{ item.first_name }} {{ item.last_name }}
              </span>
            </td>
            <td>
              <span v-if="item.company !== null">{{ item.company.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.app_user_role !== null"
                class="type-style"
              > {{ item.app_user_role | convertSiteManager }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <span v-if="item.worker_category !== null">{{ item.worker_category.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ item.work_hours }} Hrs</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font v-if="item.created_by === null">
                Outsider
                <v-icon
                  v-if="item.request_status === 'invited'"
                  @click="inviteOutSider(item.app_user_organization_id)"
                >
                  mdi-send
                </v-icon>
                <v-icon v-if="item.request_status === 'pending'">
                  mdi-check
                </v-icon>
              </font>
              <font v-else>
                User
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="getAppUserDetails(item.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2  action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="updateAppUserDetails(item.external_id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2  action-btn"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click.stop
                @click="deleteAppUser(item.external_id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner :loading="loading" />
    <edit-app-user
      v-if="showEditForm"
      :app-user-id="appUserId"
      @closed="showEditForm = false"
      @success="getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 })"
    />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this app user ?'"
      :message="'Action will permanently remove the  user from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import EditAppUser from 'src/views/dashboard/appusers/EditAppUser';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import Constants from 'src/constants';

export default {
  name: 'AppUsersActive',
  components: {
    'centre-spinner': spinner,
    'edit-app-user': EditAppUser,
    'delete-app-user': DeleteDialog,
  },
  filters: {
    arrangePretty (val) {
      return val.join(', ');
    },
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    convertSiteManager (val) {
      if (val === 'site-manager' || val === 'staff') {
        return 'Site Manager';
      }
      return val;
    },
  },
  data () {
    return {
      search: null,
      showForm: false,
      loading: false,
      headers: [
        { text: 'Name', align: 'start', value: 'first_name' },
        { text: 'Company', value: 'company' },
        { text: 'Role', value: 'role' },
        { text: 'Category', value: 'worker_category' },
        { text: 'Work Hours', value: 'work_hours' },
        { text: 'Added On', value: 'created_at' },
        { text: 'Type', value: 'created_by' },
        { text: 'More', value: 'more' },
        { text: 'Edit', value: 'edit' },
        { text: 'Delete', value: 'delete' },

      ],
      showEditForm: false,
      appUserId: '',
      showDelete: false,
      selectedCompany: null,
      updateProgress: false,
      userId: '',
      options: {},
      itemsPerPage: 10,
      pageStart: 0,
    };
  },
  computed: {
    companiesList () {
      return this.$store.getters['companies/getCompaniesList'];
    },
    paginatedAppUsersStructure () {
      return this.$store.getters['appusers/getAppUsers'];
    },
    appUsers () {
      const records = this.paginatedAppUsersStructure;
      return records.data;
    },
    totalRecords () {
      if (Object.keys(this.paginatedAppUsersStructure).length > 0) {
        return this.paginatedAppUsersStructure.total;
      }
      return 0;
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
    prePaginationNumber () {
      return this.$store.getters['records/getPrePaginationNumber'];
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    await this.getCompaniesList();
    await this.checkPreRoute();
  },
  methods: {
    async getAllRecordsFromServer () {
      this.updateProgress = true;
      this.search = null;
      this.selectedCompany = null;
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      await this.paginatedCall();
      this.updateProgress = false;
    },
    async handleSearch () {
      this.options.itemsPerPage = 10;
      this.options.page = 1;
      await this.paginatedCall();
    },
    async clearHandleSearch () {
      this.search = null;
      this.options.itemsPerPage = 10;
      this.options.page = 1;
      await this.paginatedCall();
    },
    async handleCompanyFilter () {
      this.options.itemsPerPage = 10;
      this.options.page = 1;
      await this.paginatedCall();
    },
    async getRecordsFromServer () {
      this.updateProgress = true;
      await this.$store.dispatch('appusers/fetchAppUsers', {
        params: {
          params: {
            name: this.search === '' ? null : this.search,
            companyId: this.selectedCompany,
            limit: this.itemsPerPage,
            startPage: this.pageStart,
          },
        },
      }).catch(() => {
        this.updateProgress = false;
      });
      this.updateProgress = false;
    },
    async getCompaniesList () {
      this.loading = true;
      await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    getAppUserDetails (externalId) {
      this.$router.push(`/app-users/${externalId}`);
    },
    groupAppUsers () {
      this.$router.push({ name: 'App Users Structure' });
    },
    updateAppUserDetails (id) {
      this.appUserId = id;
      this.showEditForm = true;
    },
    deleteAppUser (id) {
      this.appUserId = id;
      this.showDelete = true;
    },
    deleteConfirmed () {
      this.loading = true;
      this.$store.dispatch('appusers/deleteAppUser', {
        appUserId: this.appUserId,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'App user deleted successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.showDelete = false;
        this.loading = false;
        this.getRecordsFromServer();
      }).catch(() => {
        this.loading = false;
      });
    },
    showEmgContactDetails (id) {
      this.$emit('showEmergencyContact', id);
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      await this.$store.dispatch('records/savePrePaginationNumber', this.options.page);
      this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
      this.getRecordsFromServer();
    },
    async checkPreRoute () {
      const preRouteName = this.$router.history.current.meta.previousRoute.name;
      if ((Object.keys(this.paginatedAppUsersStructure).length !== 0) && preRouteName === 'App User Details') {
        this.options.page = this.prePaginationNumber;
      }
    },
    async inviteOutSider (id) {
      await this.$store.dispatch('appusers/inviteOutsider', {
        params: {
          app_users: [id],
        },
      }).then(response => {
        this.getRecordsFromServer();
        this.$store.dispatch('alert/onAlert', {
          message: 'Invited Successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
      });
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}

.type-style {
  text-transform: capitalize;
}

.all-users-btn {
  margin-bottom: 25px;
}

.wrk-hr-btn {
  margin-right: 15px;
}

.v-card {
  margin-top: 0px !important;
}

.info-container {
  cursor: pointer;
  color: #37474F;
}

.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.project-list-card {
  box-shadow: none !important;
}

.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.action-btn {
  background: #fff !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.info-text {
  margin-bottom: 0px !important;
}
</style>
